@import 'scss/mixins';

.tab_button_container {
    @include blue_flash_kill();
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;

    .tabs {
        display: flex;
        position: relative;
        background-color: #fff;
        padding: 3px;
        border-radius: 30px;
        transform: scale(0.8);

        * {
            z-index: 2;
        }

        [aria-selected='true'] {
            &[id='tab-1'] {
                & ~ .glider {
                    transform: translateX(0%);
                }
            }
            &[id='tab-2'] {
                & ~ .glider {
                    transform: translateX(100%);
                }
            }
            &[id='tab-3'] {
                & ~ .glider {
                    transform: translateX(200%);
                }
            }
            &[id='tab-4'] {
                & ~ .glider {
                    transform: translateX(300%);
                }
            }
        }

        @include respond_to(md) {
            transform: scale(1.44);
        }
    }

    .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 38px;
        width: 100px;
        font-size: 14px;
        color: $secondary;
        font-weight: 700;
        border-radius: 30px;
        cursor: pointer;
    }

    .glider {
        position: absolute;
        display: flex;
        height: 38px;
        width: 100px;
        background-color: $primary;
        z-index: 1;
        border-radius: 30px;
        @include basic_transition(transform, 0.25);
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
}
