@import 'scss/_mixins';

.accordion {
    display: block;
    width: 100%;
    max-width: 1280px;
    @include push_auto;
    @include blue_flash_kill;

    li {
        list-style: none;
    }

    @include respond_to(lg) {
        display: none;
    }
}
