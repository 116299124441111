@import 'scss/_variables';
@import 'scss/_mixins';

.search_wrapper {
    width: 100vw;
    position: fixed;
    z-index: 3;
    top: 60px;
    left: 0;

    @include respond_to(lg) {
        top: 146px;
    }
}

.search {
    height: 0px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    @include basic_transition();
}

.open {
    height: 130px;

    @include respond_to(lg) {
        height: 120px;
    }

    .search_content {
        opacity: 1;
        @include basic_transition();
        transition-delay: 0.2s;
    }
}

.close {
    height: 0px;

    .search_content {
        opacity: 0;
        @include basic_transition();
    }
}

.search_content {
    opacity: 0;
    margin-top: 24px;
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include basic_transition();
    transition-delay: 2s;
}

.search_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 744px;
    margin-bottom: 12px;

    @include respond_to(lg) {
        margin-bottom: 32px;
    }

    svg {
        cursor: pointer;
        @include square(1rem);

        @include respond_to(lg) {
            @include square(size(22));
        }
    }

    .search_ico {
        position: absolute;
        left: 0;
    }

    .close_ico {
        position: absolute;
        right: 0;
    }
}

.input {
    @include blue_flash_kill;
    width: 100%;
    height: 32px;
    padding-right: 32px;
    padding-left: 32px;
    @include set_font(16px, #001526, 400, 18px);
    background-color: #fff;
    outline: none;
    border: none;
    border-bottom: 1px solid #c4c4c4;
    user-select: text; // for safari
    transition: all 0.3s ease-out;
    box-shadow: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #fff;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    &::-webkit-input-placeholder {
        @include set_font(16px, #c4c4c4, 400, 18px);
    }

    // for removing arrows on chrome
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield; // for removing arrows on mozilla
}

.results_wrapper {
    position: absolute;
    height: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    @include basic_transition(all, 0.8);
    overflow: auto;
    z-index: 1000;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &.open {
        height: calc(100vh - 160px);
        @include basic_transition(all, 0.8);

        @include respond_to(lg) {
            height: calc(100vh - 266px);
        }
    }

    &.close {
        height: 0px;
        @include basic_transition();
    }
}

.no_results {
    color: #585858;
}

.no_data {
    opacity: 0;
    @include set_font(34px, 36px, 500, -0.34px);
    margin-top: 140px;
    animation: fade_in cubic-bezier(0.215, 0.61, 0.355, 1) 2s forwards;
    animation-delay: 0.2s;
}

@keyframes fade_in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
