@import 'scss/variables';
@import 'scss/mixins';

.spinner_wrapper {
    width: 100vw;
    height: 100vh;
}

.group {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;

    span {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        background-color: $secondary;
        display: inline-block;
        animation: square4 0.8s infinite ease-in-out both;

        &:nth-child(1) {
            left: 0px;
        }

        &:nth-child(2) {
            left: 15px;
            animation-delay: 0.1s;
        }

        &:nth-child(3) {
            left: 30px;
            animation-delay: 0.2s;
        }

        &:nth-child(4) {
            left: 45px;
            animation-delay: 0.3s;
        }
    }
}

@keyframes square4 {
    0% {
        transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateX(180deg);
        transform: rotateX(180deg);
    }
}
