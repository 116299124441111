@import 'scss/variables';
@import 'scss/mixins';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 220px;
    height: 50px;
    background: $primary;
    border: none;
    border-radius: 1px;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $secondary;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    &:focus {
        outline: none;
    }

    // transition: background 0.3s ease-out;
    @include basic_transition(all, 0.35);

    // &:focus,
    // &:hover {
    //     background: $secondary;
    //     color: $primary;
    //     border: 1px solid $primary;
    // }

    &::before,
    &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &::before,
    &::after {
        // Set border to invisible, so we don't see a 1px border on a 0x0 element before the transition starts
        border: 1px solid transparent;
        width: 0;
        height: 0;
    }

    // This covers the top & right borders (expands right, then down)
    &::before {
        top: 0;
        left: 0;
    }

    // And this the bottom & left borders (expands left, then up)
    &::after {
        bottom: 0;
        right: 0;
    }

    &:hover {
        background: $secondary;
        color: $primary;
    }

    // Hover styles
    &:hover::before,
    &:hover::after {
        width: 100%;
        height: 100%;
    }

    &:hover::before {
        border-top-color: $primary; // Make borders visible
        border-right-color: $primary;
        transition: width 0.15s ease-out,
            // Width expands first
            height 0.15s ease-out 0.15s; // And then height
    }

    &:hover::after {
        border-bottom-color: $primary; // Make borders visible
        border-left-color: $primary;
        transition: border-color 0s ease-out 0.3s,
            // Wait for ::before to finish before showing border
            width 0.2s ease-out 0.3s,
            // And then exanding width
            height 0.25s ease-out 0.5s; // And finally height
    }
}
