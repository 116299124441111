@import 'scss/variables';
@import 'scss/mixins';

.main {
    min-height: auto;
    padding-top: size(60);
    overflow-x: hidden;

    @include respond_to(lg) {
        padding-top: size(146);
    }
}

.cookie_banner_wrapper {
    width: 100vw;
    height: 160px;
    background-color: $secondary;
    @include push_auto;
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: apear 0.3s ease-in;
    z-index: 500;

    // @media screen and (min-width: 768px) {
    // 	display: flex;
    // 	padding: 20px 44px 20px 28px;
    // }

    @include respond_to(xl) {
        width: 100%;
        height: 100px;
        padding: 20px 100px;
        flex-direction: row;
        justify-content: space-between;
    }

    @keyframes apear {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    p {
        font-family: Barlow;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #fff;
        margin-bottom: 22px;
        text-align: center;

        @include respond_to(xl) {
            font-size: 18px;
            line-height: 24px;
            max-width: 60%;
            margin-bottom: 0;
            text-align: left;
        }

        a {
            color: #fff;
            text-decoration: underline;
            @include basic_transition();

            &:hover {
                color: $primary;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;

        @include respond_to(xl) {
            display: flex;
        }
    }

    .button {
        width: 170px;
        height: 40px;
        font-size: 14px;

        @include respond_to(xl) {
            width: 200px;
            height: 50px;
            font-size: 16px;
        }

        &:nth-of-type(1) {
            margin-right: 10px;
        }
    }
}

.show_banner {
    display: flex;
}

.hide_banner {
    display: none;
}
