@import 'scss/variables';
@import 'scss/mixins';

.toggle_button {
    @include blue_flash_kill();
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 16px;
    width: 19px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }

    @include respond_to(lg) {
        display: none;
    }
}

.toggle_button_line {
    width: 19px;
    height: 1px;
    background: #fff;
}
