@import 'scss/variables';
@import 'scss/mixins';

:root {
    --header: #00243c;
    --primary: #e8b629;
    --secondary: #0c293d;
    --additional-text: #c4c4c4;
    --warning: #f10000;
}

$animation-duration: 1.8s;

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Barlow', sans-serif;
    // font-family: 'Barlow Semi Condensed', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

.no_scroll {
    overflow: hidden;
}

.spinner {
    height: 5vmin;
    left: 50%;
    margin: -2vmin 0 0 -2vmin;
    position: fixed;
    top: 50%;
    width: 5vmin;

    &:before {
        animation: blueRoute 1s linear infinite;
        background-color: #0c293d;
        content: '';
        height: 2vmin;
        left: 0;
        position: absolute;
        top: 0;
        width: 2vmin;
    }
    &:after {
        animation: pinkRoute 1s linear infinite;
        background-color: #e8b629;
        content: '';
        height: 2vmin;
        left: 2vmin;
        position: absolute;
        top: 2vmin;
        width: 2vmin;
    }
}

@keyframes blueRoute {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    25% {
        transform: translate(5vmin, 0) rotate(90deg);
    }
    50% {
        transform: translate(5vmin, 5vmin) rotate(180deg);
    }
    75% {
        transform: translate(0, 5vmin) rotate(270deg);
    }
    100% {
        transform: translate(0, 0) rotate(360deg);
    }
}

@keyframes pinkRoute {
    0% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(-5vmin, 0);
    }
    50% {
        transform: translate(-5vmin, -5vmin);
    }
    75% {
        transform: translate(0, -5vmin);
    }
    100% {
        transform: translate(0, 0);
    }
}
