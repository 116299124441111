@import 'scss/variables';
@import 'scss/mixins';

.menu {
    &[aria-expanded='true'] {
        transform: translateX(0);
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: size(10) 1rem size(10) 1.8rem;
    cursor: pointer;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: size(14);
    line-height: size(24);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: $primary;
    outline: none;
    background: transparent;
    border: none;

    &:focus {
        outline: none;
    }
}

.buttons_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

.menu {
    height: 100%;
    background: $secondary;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    transform: translateX(-100%);
    @include basic_transition(transform, 0.3);
    padding: size(20) 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: size(10) 1rem size(10) 1.8rem;
    cursor: pointer;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: size(14);
    line-height: size(24);
    letter-spacing: 0.05rem;
    color: $primary-text;

    &:focus {
        outline: none;
    }
}

.buttons_wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.close_button {
    border: none;
    margin: 0;
    padding: 0;
    height: 24px;
    overflow: visible;
    outline: none;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: size(14);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: $primary;
    appearance: none;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:first-of-type {
        > svg {
            margin-left: size(8);
        }
    }
}
