@import 'scss/variables';
@import 'scss/mixins';

.home_category_item {
    // padding: 0 15px;
    position: relative;
    min-height: 457px;
    margin-bottom: 1rem;
    min-width: 100%;

    img {
        width: 345px;
        height: 100%;
        @include basic_transition();
    }

    @include respond_to(lg) {
        position: relative;
        max-width: 440px;
        // max-width: 100%;
        max-height: 583px;
        margin-bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 11 / 15;
        }
    }
}

.link_wrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;

    .button {
        position: absolute;
        bottom: 44px;
    }

    &:hover {
        img {
            transform: scale(1.3);
        }

        .button {
            background: $secondary;
            color: $primary;
            font-weight: 600;
        }
    }
}
