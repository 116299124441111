@import 'scss/variables';
@import 'scss/mixins';

.slider_wrapper {
    // padding: 0 40px;
    height: 600px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    @include respond_to(xl) {
        // padding: 0 40px;
        margin-top: 0;
        height: 768px;
    }
}

.background {
    width: 100vw;
    height: 600px;
    background-image: url('../../../../assets/images/slider-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 600px;
    overflow: visible !important;

    @include respond_to(xl) {
        background-size: 100% 768px;
        padding: 0;
        width: 100%;
        height: 100%;
    }
}

.banner_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
    // padding: 20px 16px;

    @include respond_to(xl) {
        padding: 0;
    }
}

.slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
    width: 100vw;
    // height: 100vh;
    padding: 20px 15px;

    video {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 350px;
    }

    img {
        min-width: 100%;
        height: 350px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @include respond_to(lg) {
        padding: 24px;
    }

    @include respond_to(xl) {
        padding: 0;

        img {
            width: 100%;
            height: 100%;
        }

        video {
            width: 100%;
            height: 100%;
        }
    }
}

.hero_banner {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

    @include respond_to(xl) {
        padding: 50px 80px 50px 65px;
        height: 768px;

        z-index: -1;
    }

    .hero_banner_second {
        width: 100%;
        height: 350px;

        @include respond_to(xl) {
            height: 100%;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 9 / 8;
        z-index: 1;
    }
}

.inner_wrapper {
    width: 100%;
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 35px 0 25px;

    h2 {
        font-family: 'Barlow Semi Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #fff;
    }

    > p {
        font-family: 'Barlow Semi Condensed';
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        text-transform: uppercase;
        margin: 5px 0 15px;
        color: #ffffff;
    }

    @include respond_to(xl) {
        width: 630px;
        position: absolute;
        left: 65px;
        bottom: 48px;
        padding: 30px 0;
        align-items: flex-start;
        margin: 0;

        h2 {
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            font-variant: small-caps;
        }

        p {
            font-size: 50px;
            line-height: 60px;
            margin-bottom: 1rem;
            text-align: center;
        }
    }
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 25px;
    left: 50%;
    top: unset;
    right: unset;
    transform: translateX(-50%);

    @include respond_to(xl) {
        flex-direction: column;
        top: 50%;
        right: 25px;
        bottom: unset;
        left: unset;
        transform: translateY(-50%);
    }
}

.dot_wrapper {
    @include blue_flash_kill();
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    @include square(size(24));

    &:focus {
        outline: none;
    }
}

.dot {
    border: none;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;

    &:focus {
        outline: none;
    }
}

.active {
    background-color: $primary;
}

// .banner_media {
//     @include respond_to(md) {
//         display: none !important;
//     }
//     &_laptop {
//         display: none !important;

//         @include respond_to(md) {
//             display: block !important;
//         }
//     }
//     &_desktop {
//         display: none !important;

//         @include respond_to(lg) {
//             display: block !important;
//         }
//     }
// }
