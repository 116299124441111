@import 'scss/variables';
@import 'scss/mixins';

.breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;

    a {
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        color: #979797;
        // text-transform: lowercase;

        @include respond_to(xl) {
            font-size: 16px;
            line-height: 19px;
        }

        &::after {
            content: '>';
            margin: 0 5px;
        }

        &:nth-last-child(1) {
            color: $secondary;
            pointer-events: none;

            &::after {
                content: '';
            }
        }
    }
}
