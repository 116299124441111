@import 'scss/variables';
@import 'scss/mixins';

.header {
    width: 100%;
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
}

.heder_top {
    position: relative;
    width: 100%;
    height: size(60);
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $secondary;
    padding: 0 1rem;

    @include respond_to(lg) {
        height: size(73);
        padding: 0 size(40);
    }

    a {
        > svg {
            width: size(70);
            height: size(30);

            @include respond_to(lg) {
                width: size(110);
                height: size(42);
            }
        }
    }
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include respond_to(lg) {
        height: size(50);
        width: size(120);
    }
}

.heder_bottom {
    display: none;

    @include respond_to(lg) {
        width: 100%;
        height: size(73);
        padding: 0 size(40);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        > ul {
            list-style: none;
            display: flex;
            align-items: center;
            height: 100%;

            > li {
                display: flex;
                align-items: center;
                height: 100%;

                &:hover {
                    .tablist[aria-hidden='false'] {
                        display: block;
                        animation: fade_in cubic-bezier(0.215, 0.61, 0.355, 1)
                            0.5s;
                    }

                    .title {
                        color: $primary;
                    }
                }

                &:not(:last-of-type) {
                    margin-right: size(26);
                }

                .nav_item {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    // text-transform: uppercase;
                    color: $secondary;
                    position: relative;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
}

@keyframes fade_in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.nav_wrapper {
    display: flex;
    z-index: 2;
}

.nav_list {
    list-style: none;
    display: flex;
    align-items: center;

    > li {
        display: flex;

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
                @include square(1.5rem);

                @include respond_to(lg) {
                    @include square(size(22));
                }
            }
        }

        &:not(:last-of-type) {
            margin-right: 0.6rem;

            @include respond_to(lg) {
                margin-right: 1rem;
            }
        }

        > svg {
            @include square(1rem);

            @include respond_to(lg) {
                @include square(size(22));
            }
        }
    }
}

.nav_btn {
    background: url('~assets/icons/menu.svg');
    width: 1.2rem;
    height: 0.8rem;
}

.tablist {
    display: none;
    position: absolute;
    left: 0;
    top: 146px;
    width: 100vw;
    // height: 100vh;
    max-height: 763px;
    background-color: $secondary;
    z-index: 2;
    padding-top: size(58);
    padding-bottom: size(58);

    p {
        color: #fff;
    }

    & > ul {
        height: 100%;
        max-height: 563px;
        margin: 0 auto;
        width: 60%;
        // columns: 4 auto;
        // margin-bottom: 2rem;
        // column-gap: 24px;
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-wrap: wrap;

        > li {
            padding: 0 3%;
            padding-bottom: 60px;
            width: 25%;
        }

        li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            text-transform: capitalize;
            color: #fff;
            // page-break-inside: avoid; /* Firefox */
            // break-inside: avoid;

            &:hover {
                color: $primary;
            }

            > svg {
                @include square(size(30));
            }
        }
    }
}

.title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: $secondary;

    &:hover {
        color: $primary;
    }
}

.subtitle {
    @extend .title;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.profile_wrapper {
    position: relative;
    width: auto;
    height: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .profile_menu {
        position: absolute;
        bottom: -135px;
        right: -50px;
        background-color: $secondary;
        color: #fff;
        width: 170px;
        height: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: mainShow 0.3s ease-in-out;

        p {
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #e5e5e320;
            padding-bottom: 15px;
            margin-bottom: 15px;
            width: 100%;

            &:hover {
                color: $primary;
            }
        }

        svg {
            @include square(15px);
            margin-right: 17px;
            animation: none !important; // for disabling animation on profile menu
        }

        @keyframes mainShow {
            from {
                opacity: 0;
                transform: translateY(-20px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .logout {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.search_icon {
    svg {
        &:hover {
            animation: loop 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both !important;
            transform-origin: bottom left;
        }

        @keyframes loop {
            0% {
                transform: rotate(0);
            }
            30% {
                transform: rotate(-20deg);
            }
            60% {
                transform: rotate(10deg);
            }
            100% {
                transform: rotate(0);
            }
        }
    }
}

.icon {
    position: relative;

    .count {
        @include square(18px);
        position: absolute;
        top: -10px;
        right: -8px;
        color: #fff;
        background-color: $primary;
        border-radius: 50%;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        &:nth-of-type(1) {
            transition: all 0.2s ease-in-out;

            &:hover {
                animation: scaleout 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97)
                    both;
            }
        }
    }
}

@keyframes scaleout {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(1);
    }
}
