@import 'scss/variables';
@import 'scss/mixins';

.home_banner_section {
    width: 100%;
    height: auto;
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    position: relative;

    img {
        height: 200px;
        object-fit: cover;
        aspect-ratio: 3 / 4;
    }

    .banner_content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 44px;

        p {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: $primary;
            margin-bottom: 24px;
        }
    }

    @include respond_to(xl) {
        height: 578px;
        flex-direction: row;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 33 / 43;
        }

        .banner_content {
            justify-content: flex-end;
            padding-bottom: 77px;

            p {
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                max-width: 400px;
                color: $primary;
                margin-bottom: 24px;
            }
        }
    }
}
