@import 'scss/variables';
@import 'scss/mixins';

.content_small {
    user-select: none;
    padding: 100px 0 50px 0;
    background-color: $primary;
    text-align: center;
    position: relative;

    span {
        font-family: Barlow;
        font-style: normal;
        font-size: 170px;
        line-height: 150px;
        text-transform: uppercase;
        color: #e5e5e360;
        position: absolute;
        top: 20px;
        z-index: 1;
        letter-spacing: 5px;
        user-select: none;
        text-align: center;
        left: 0;
        width: 100%;
    }

    p {
        position: relative;
        font-family: Barlow Semi Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 55px;
        text-transform: uppercase;
        color: #ffffff;
        z-index: 2;
        margin-bottom: 20px;
    }

    @include respond_to(md) {
        display: none;
    }
}

.content {
    display: none;

    .text {
        position: relative;
    }

    @include respond_to(md) {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 40px;
        user-select: none;
        bottom: 0;
        background-color: transparent;
    }

    @include respond_to(lg) {
        min-width: 380px;
        align-items: unset;
    }

    p {
        position: relative;
        font-family: Barlow Semi Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 55px;
        text-transform: uppercase;
        color: #ffffff;
        z-index: 2;

        @include respond_to(lg) {
            font-size: 100px;
            line-height: 120px;
        }
    }

    span {
        font-family: Barlow;
        font-style: normal;
        font-size: 100px;
        line-height: 155px;
        text-transform: uppercase;
        color: #e5e5e360;
        position: absolute;
        z-index: 1;
        letter-spacing: 20px;
        user-select: none;
        top: -80px;
        left: -20px;

        @include respond_to(lg) {
            font-size: 230px;
            line-height: 268px;
            top: -140px;
            left: -30px;
        }
    }
}

.sale_banner_left_overlay {
    display: block;

    @include respond_to(md) {
        position: absolute;
        left: 0;
        top: 0;
        width: 270px;
        height: 100%;
        background-color: $primary;
        &::after {
            content: '';
            display: block;
            left: 270px;
            position: absolute;
            border-style: solid;
            border-width: 700px 0 0 300px;
            border-color: transparent transparent transparent $primary;
        }

        &::before {
            content: '';
            position: absolute;
            width: 300px;
            height: 300px;
            left: -150px;
            top: -150px;
            display: block;
            transform: rotate(45deg);
            z-index: 1;
            background: repeating-linear-gradient(
                90deg,
                transparent,
                transparent 12px,
                white 12px,
                white 20px
            );
        }
    }
}

.mobile_banner {
    height: 250px;
    position: relative;
    background-position: top center;
    background-size: cover;
    overflow: hidden;

    &::after {
        content: '';
        width: 100px;
        height: 100px;
        display: block;
        background: repeating-linear-gradient(
            90deg,
            transparent,
            transparent 5px,
            #eab82a 6px,
            #eab82a 10px
        );
        transform: rotateZ(45deg);
        position: absolute;
        left: -50px;
        top: -50px;
        z-index: 1;
    }

    @include respond_to(md) {
        display: none;
    }
}

.banner {
    @include respond_to(md) {
        margin-bottom: 50px;
        overflow: hidden;
        position: relative;
        background-position: left 200px top;
        background-repeat: no-repeat;
        background-size: cover;

        width: 100%;
        display: flex;
        align-items: flex-end;
        min-height: 350px;

        &::after {
            content: '';
            width: 300px;
            height: 300px;
            display: block;
            background: repeating-linear-gradient(
                90deg,
                transparent,
                transparent 12px,
                #eab82a 12px,
                #eab82a 20px
            );
            transform: rotateZ(45deg);
            position: absolute;
            right: -150px;
            bottom: -150px;
            z-index: 1;
        }
    }

    @include respond_to(lg) {
        min-height: 700px;
    }
}

.sale_button {
    width: 220px !important;
}
