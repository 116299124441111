@import 'scss/variables';
@import 'scss/mixins';

.highlight_section {
    width: 100%;
    position: relative;
    padding: 38px 15px 20px;

    @include respond_to(xl) {
        padding: 85px 40px;

        .highlight_section_items {
            display: grid;
            grid-auto-flow: row;
            gap: 14px 0;
            grid-template-columns: 1fr;
            padding: 0 0.5rem;

            &[aria-hidden='true'] {
                display: none;
            }

            @include respond_to(lg) {
                gap: size(14) size(20);
                grid-template-columns: repeat(3, 1fr);
                padding: 0 size(40);
            }
        }
    }
}

.highlight_section_items {
    display: grid;
    grid-auto-flow: row;
    gap: 14px 0;
    grid-template-columns: 1fr;
    padding: 0 0.5rem;

    &[aria-hidden='true'] {
        display: none;
    }

    @include respond_to(md) {
        gap: size(14) size(20);
        grid-template-columns: repeat(3, 1fr);
        padding: 0 size(40);
        // justify-items: center;
        max-width: 1520px;
        margin: 24px auto;
    }
}
