@import 'scss/variables';
@import 'scss/mixins';

.side_drawer {
    @include blue_flash_kill();
    height: 100%;
    background: $secondary;
    position: fixed;
    top: size(60);
    left: 0;
    width: 100%;
    z-index: 3;
    transform: translateX(-100%);
    @include basic_transition(transform, 0.3);
    padding: size(20) 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.open {
    transform: translateX(0);
}

.close_button {
    border: none;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    overflow: visible;
    outline: none;
    align-self: flex-end;
    margin-right: 1rem;
    cursor: pointer;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    text-align: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

.menu {
    padding: 1rem 0 0;
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: size(10) 1rem size(10) 1.8rem;
    cursor: pointer;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: size(14);
    line-height: size(24);
    letter-spacing: 0.05rem;
    color: $primary-text;

    &:focus {
        outline: none;
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: size(10) 1rem size(10) 1.8rem;
    cursor: pointer;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: size(14);
    line-height: size(24);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: $primary;
    outline: none;
    background: transparent;
    border: none;

    &:focus {
        outline: none;
    }
}
