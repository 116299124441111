@import 'scss/variables';
@import 'scss/mixins';

.display {
    display: flex;
    align-items: center;

    p {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        margin-right: 8px;
        color: $primary;
    }
}

.dots {
    display: flex;
    align-items: flex-end;

    .active_dot {
        background-color: $primary;
    }
}

.dot_1 {
    width: size(8);
    height: size(8);
    border-radius: 50%;
    margin: 0 size(5);
    cursor: pointer;
    background-color: #fff;
    border: size(2) solid $primary;
}

.dot_2 {
    @extend .dot_1;
    width: size(12);
    height: size(12);
}

.dot_3 {
    @extend .dot_1;
    width: 1rem;
    height: 1rem;
    margin-right: 0;
}