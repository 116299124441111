@import 'scss/variables';
@import 'scss/mixins';

.highlight_category_item {
    width: 100%;
    height: 100%;
    max-height: 415px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 35px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    a {
        position: relative;
        width: 100%;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 100%;
        }

        & > img {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            width: 100%;
        }
    }

    img {
        @include basic_transition();
    }

    button {
        width: 130px;
        height: 30px;
        border: none;
        background-clip: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        color: $secondary;
        outline: none;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include respond_to(md) {
        max-height: 415px;
        overflow: hidden;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        button {
            width: 200px;
            height: 50px;
        }

        &:hover {
            img {
                transform: scale(1.3);
            }

            button {
                background-color: $secondary;
                cursor: pointer;
                color: #fff;
            }
        }
    }
}
