@import 'scss/variables';
@import 'scss/mixins';

.section {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $secondary;

    @include respond_to(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.title {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 12px;

    @include respond_to(lg) {
        font-size: 36px;
        line-height: 43px;
    }
}

.form {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;

    @include respond_to(sm) {
        flex-wrap: nowrap;
        width: auto;
    }
}

.form_submit_button {
    height: 30px;
    font-size: 14px;
    max-width: 140px;
    min-width: 140px;

    @include respond_to(lg) {
        font-size: 16px;
        height: 35px;
        max-width: 165px;
        min-width: 165px;
    }
}

.text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 24px;
    max-width: 440px;

    @include respond_to(lg) {
        font-size: 16px;
    }
}

.input {
    @include blue_flash_kill;
    width: 100%;
    padding: 12px 0;
    margin-bottom: 16px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--primary);
    user-select: text; // for safari
    transition: all 0.3s ease-out;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: $primary-text;

    @include respond_to(sm) {
        max-width: 300px;
        min-width: 300px;
        margin-right: 16px;
        margin-bottom: 0px;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $primary-text;
        box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    &::-webkit-input-placeholder {
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $additional-text;
    }
}
