@import 'scss/variables';
@import 'scss/mixins';

.button {
    min-width: 170px;
    height: 45px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: $secondary;
    background-color: #fff;
    outline: none;
    @include basic_transition(all, 0.35);
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid $secondary;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    @include respond_to(lg) {
        min-width: 200px;
        height: 50px;
        font-size: 16px;
        line-height: 19px;
        transition: 0.2s ease-in;

        &::before,
        &::after {
            box-sizing: inherit;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
        }

        &::before,
        &::after {
            // Set border to invisible, so we don't see a 1px border on a 0x0 element before the transition starts
            border: 1px solid transparent;
            width: 0;
            height: 0;
        }

        // This covers the top & right borders (expands right, then down)
        &::before {
            top: 0;
            left: 0;
        }

        // And this the bottom & left borders (expands left, then up)
        &::after {
            bottom: 0;
            right: 0;
        }

        &:hover {
            background: $secondary;
            color: $primary;
            border: none;
        }

        // Hover styles
        &:hover::before,
        &:hover::after {
            width: 100%;
            height: 100%;
        }

        &:hover::before {
            border-top-color: $primary; // Make borders visible
            border-right-color: $primary;
            transition: width 0.15s ease-out,
                // Width expands first
                height 0.15s ease-out 0.15s; // And then height
        }

        &:hover::after {
            border-bottom-color: $primary; // Make borders visible
            border-left-color: $primary;
            transition: border-color 0s ease-out 0.3s,
                // Wait for ::before to finish before showing border
                width 0.2s ease-out 0.3s,
                // And then exanding width
                height 0.25s ease-out 0.5s; // And finally height
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;

            &:hover {
                color: $secondary;
                background-color: #fff;
            }
        }
    }
}
