@import 'scss/variables';
@import 'scss/mixins';

.button {
    width: 100%;
    height: 50px;
    background-color: transparent;
    outline: none;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-transform: uppercase;
    cursor: pointer;
    @include basic_transition();

    &:hover {
        color: $primary;
        border: 1px solid $primary;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}
