@import 'scss/variables';
@import 'scss/mixins';

.list {
    padding: 0;
    margin: 0;

    li {
        list-style-type: none;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.list_title {
    margin-bottom: 24px;
}

.product_category_page {
    display: flex;
    padding: 20px 15px;
    min-height: calc(100vh - 60px);

    .aside {
        display: none;

        @include respond_to(lg) {
            display: block;
            flex: 0 0 15%;
            max-width: 15%;
            margin-right: 0.8rem;
        }
    }

    .content {
        width: 100%;
    }

    @include respond_to(xl) {
        min-height: calc(100vh - 146px);
        padding: 25px 40px;
    }

    .filter_and_display {
        margin: 20px 0 10px;
        display: flex;
        justify-content: space-between;

        @include respond_to(xl) {
            margin: 24px 0;
        }
    }

    .filter {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            margin-left: 8px;
            color: $primary;
        }

        svg {
            width: 15px;
            height: 15px;
        }

        @include respond_to(xl) {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 18px;
                line-height: 22px;
            }

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    .grid_filter {
        display: none;
        @include respond_to(sm) {
            display: block;
        }
    }

    .product_category_content {
        @include basic_transition();

        display: grid;
        grid-column-gap: size(7);
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: size(24);

        @include respond_to(xl) {
            padding: 1rem 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
            grid-column-gap: size(11);
            grid-row-gap: size(40);
        }
    }

    .button_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .button {
        width: 250px;
        height: 50px;
        background-color: #fff;
        border: 1px solid $secondary;
        outline: none;
        border-radius: 2px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        margin-top: 24px;
    }

    .button:disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
}
