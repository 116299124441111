@import 'scss/variables';
@import 'scss/mixins';

.products_slider_section {
    width: 100%;
    padding: 20px 15px;

    .product_tile_slider {
        width: 100%;
        height: auto;
        overflow: visible;

        @include respond_to(xl) {
            height: 713px;
        }

        &:hover {
            transform: scale(1.07);
        }
    }

    h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: $primary;
        margin-bottom: 24px;
        text-align: center;
    }

    @include respond_to(xl) {
        width: 100%;
        padding: 95px 40px;

        h2 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 43px;
            text-align: left;
        }
    }
}
