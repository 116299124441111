@import 'scss/variables';
@import 'scss/mixins';

.panel {
    border-bottom: 1px solid rgba(#e8b629, 0.4);

    // &:last-of-type {
    // 	border-bottom: none;
    // }

    &[aria-expanded='true'] {
        .label {
            > svg {
                transform: rotate(90deg);
            }
        }

        .content {
            opacity: 1;
        }

        .inner {
            opacity: 1;
        }
    }
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: 1rem;
    cursor: pointer;
    transition: color 0.2s ease-out;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: size(14);
    line-height: size(24);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: $primary;
    border-bottom: 1px solid transparent;

    &:focus {
        outline: none;
    }

    > svg {
        transition: transform 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
}

.inner {
    opacity: 0;
    border-top: 1px solid rgba(#e8b629, 0.4);
    overflow: hidden;
    transition: opacity 0.3s, height 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.content {
    padding: 1rem;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
    font-style: normal;
    font-weight: 500;
    font-size: size(14);
    line-height: size(26);
    letter-spacing: 0.05rem;
    color: $primary-text;
}
