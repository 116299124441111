@import 'scss/variables';
@import 'scss/mixins';

.footer {
    width: 100%;
    background-color: $secondary;
    padding-bottom: 35px;

    @include respond_to(lg) {
        padding-bottom: 50px;
    }
}

.divider {
    border-color: rgba($primary, 0.1);
    margin-bottom: 26px;

    @include respond_to(lg) {
        max-width: 1142px;
        margin: 0 auto 26px;
    }
}

.logo_wrapper {
    width: 200px;
    margin-bottom: 26px;
    margin-left: 16px;
}

.logo_wrapper {
    width: 200px;
    margin-bottom: 26px;
    margin-left: 16px;
}

.social_mobile_wrapper {
    margin-left: auto;
    height: 84px;
    padding: 18px 16px;
}

.content {
    display: flex;
    flex-wrap: nowrap;
}

.mobile_content {
    display: flex;
    flex-wrap: wrap;
}

.panel {
    border-left: 1px solid $primary;
    padding-left: 16px;
    width: 100%;
    height: size(270);
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;
}

.list {
    list-style: none;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: $primary-text;

    li {
        color: $primary-text;

        &:hover {
            color: $primary;
        }

        svg {
            @include square(size(23));
        }
    }
}

.list_title {
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 8px;
}
