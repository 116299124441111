@import 'scss/variables';
@import 'scss/mixins';

.home_categories_section {
    width: 100%;
    position: relative;
    padding: 38px 15px 20px;

    .background {
        height: 284px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: $secondary;
    }

    .tab_component_header {
        h2 {
            color: $primary;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
        }
    }

    .active {
        background-color: $primary;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        @include basic_transition(all);
    }

    .products {
        display: grid;
        grid-auto-flow: row;
        gap: 14px 0;
        grid-template-columns: 1fr;
        padding: 0 0.5rem;

        &[aria-hidden='true'] {
            display: none;
        }

        @include respond_to(lg) {
            gap: size(14) size(20);
            grid-template-columns: repeat(3, 1fr);
            padding: 0 size(40);
            // justify-items: center;
            max-width: 1520px;
            margin: 24px auto;
        }
    }

    @include respond_to(xl) {
        width: 100%;
        position: relative;
        padding: 85px 40px;

        .background {
            height: 424px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background-color: $secondary;
        }

        .tab_component_header {
            height: auto;

            h2 {
                color: $primary;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 2rem;
                font-weight: 500;
                font-size: 36px;
                line-height: 43px;
                text-transform: uppercase;
            }
        }

        .active {
            background-color: $primary;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            border-radius: 30px;
            @include basic_transition(all);
        }

        .products {
            display: grid;
            grid-auto-flow: row;
            gap: 14px 0;
            grid-template-columns: 1fr;
            padding: 0 0.5rem;

            &[aria-hidden='true'] {
                display: none;
            }

            @include respond_to(lg) {
                gap: size(14) size(20);
                grid-template-columns: repeat(3, 1fr);
                padding: 0 size(40);
            }
        }
    }
}
