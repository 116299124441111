// Brands -------------------
$header: #00243c;
$primary: #fdb927;
$secondary: #001526;
$primary-text: #fff;
$additional-text: #979797;
$warning: #f10000;
// --------------------------

// Typography -------------
$base_size: 16;
// ------------------------

// Breakpoints -------------
$breakpoints: (
    'xs': (
        min-width: 350px,
    ),
    'sm': (
        min-width: 640px,
    ),
    'md': (
        min-width: 768px,
    ),
    'lg': (
        min-width: 1024px,
    ),
    'xl': (
        min-width: 1280px,
    ),
    '2xl': (
        min-width: 1536px,
    ),
) !default;

$breakpoint_415: 415px;

//  -------------------------

// Background colors --------
$bg-dimmed: #f9f9f9;
$bg-white: #fff;
