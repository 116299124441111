@use "sass:math";

@import 'variables';

//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@function size($target, $context: $base-size) {
    @return math.div($target, $context) * 1rem;
}

// Kills touch event outline on elements
@mixin blue_flash_kill {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Hide scroll (goes on parent)
@mixin hide_scroll($axis: both) {
    @if $axis == both {
        overflow: scroll;
    } @else {
        overflow-#{$axis}: scroll;
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
}

// Breakpoints
@mixin respond_to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media only screen and #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

// Basic transition for all props and 0.4s duration
@mixin basic_transition($property: all, $time_in_seconds: 0.5, $delay: 0) {
    transition: $property #{$time_in_seconds}s
        cubic-bezier(0.215, 0.61, 0.355, 1) #{$delay}s;
}

// Centre a block element
@mixin push_auto {
    margin: {
        left: auto;
        right: auto;
    }
}

@mixin square($size) {
    width: $size;
    height: $size;
}

// for creating scalable elements that maintain a ratio
@mixin responsive_ratio($x, $y, $pseudo: false) {
    $padding: unquote(math.div($y, $x) * 100 + '%');
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

// Font settings
@mixin set_font(
    $size: false,
    $colour: false,
    $weight: false,
    $line_height: false,
    $spacing: false
) {
    @if $size {
        font-size: $size;
    }
    @if $colour {
        color: $colour;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $line_height {
        line-height: $line_height;
    }
    @if $spacing {
        letter-spacing: $spacing;
    }
}
